<template>
  <div :class="['custom_tooltip', content.hasMoreDeal ? 'has-more-deal':'']">
    <div md="3" :class="from === 'search-result' ? 'px-3' : 'py-3'">
      <b-button class="program py-0" v-b-tooltip.hover.html="programs" v-if="programs"
      variant="success">{{$t("home.programs")}}</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, VBTooltip } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'CustomTooltip',
  mixins: [imageUrlMixin],
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    isDeal: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      dealItinerary: 'GET_DEAL_ITINERARY',
      lang: 'GET_LANGUAGE',
    }),
    programs() {
      let htmlPrograms = '';
      if (this.dealItinerary !== undefined && this.dealItinerary && this.dealItinerary.length) {
        const itinerary = this.dealItinerary.find((el) => this.content.destCode === el.dst && (this.isDeal || (this.content?.itinerary?.itin_id ?? '') === el.itinId));
        htmlPrograms = itinerary ? itinerary.itineraryDetail
          .map((item, index) => `<h3><img src="${this.speedSizeDomain}/assets/img/icon_ster.png" alt="star-icon" width="26" height="26"> ${this.$t('product-page.day')} ${index + 1}</h3>${item.text}`).join('<br>') : '';
      }
      return htmlPrograms;
    },
  },
};
</script>

<style scoped>
  .custom_tooltip{
    position: absolute;
    left: 50px;
    top: -5px;
  }
  .custom_tooltip.has-more-deal{
    top: 25px;
  }
  .custom_tooltip .program {
    height: 20px;
    font-size: 12px;
    font-weight: bold;
  }
</style>

<style>
  .tooltip {
    height: 500px;
    max-width: 90%;
    padding: 5px 0 0 0;
    width: 500px;
  }

  .tooltip-inner {
    overflow: auto;
    height: 100%;
    max-width: 100%;
    width: 500px;
    font-family: 'ploni', 'Poppins', sans-serif;
  }

  .text-left .tooltip-inner {
    text-align: left !important;
  }

  .tooltip-inner {
    text-align: right !important;
  }

  @media ( max-width: 479px) {
    .tooltip-inner{
      font-size: 10px;
    }
  }

</style>
